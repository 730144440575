import logo from '../../assets/img/icons/logo.webp';

import './Header.css';

function Header() {
  return (
    <header className="header">
      <div className="wrapper header-wrapper">
        <div className="header-left">
          <img src={logo} alt="logo" className="logo" width={34} height={34} />
          <p className="app-name">Followers Tracker</p>
        </div>
        <a
          href="https://apps.apple.com/tr/app/unfollowers-report-analytics/id1571096208"
          target="_blank"
          className="button-blue"
          rel="noreferrer"
        >
          Install Now
        </a>
      </div>
    </header>
  );
}

export default Header;
