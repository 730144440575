export const arrayNotifications = [
  {
    id: 1,
    title: 'Analysis is available now!',
    description: 'You have 2 new Insights',
    time: '9:41 AM',
    class: 'notification-text-up notification-text-up-detective',
  },
  {
    id: 2,
    title: 'You have new Unfollower!',
    description: 'You have 1 new Unfollower',
    time: '10:41 AM',
    class: 'notification-text-up notification-text-up-heart',
  },
  {
    id: 3,
    title: 'Weekly report is available!',
    description: 'You have 12 new Followers',
    time: '11:22 AM',
    class: 'notification-text-up notification-text-up-smile-star',
  },
  {
    id: 4,
    title: 'Discover your Admirers!',
    description: 'You have 3 new Secret Admirers',
    time: '12:48 PM',
    class: 'notification-text-up notification-text-up-smile-heart',
  },
  {
    id: 5,
    title: 'You have a new achievment!',
    description: 'Tap to see more',
    time: '12:49 PM',
    class: 'notification-text-up notification-text-up-stone',
  },
];
