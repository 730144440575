import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

try {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  const FallbackComponent = (error) => (
    <div style={{ backgroundColor: 'red', padding: '1rem' }}>{error.message}</div>
  );

  if (root) {
    root.render(
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <App />
        </ErrorBoundary>
      </React.StrictMode>
    );
  } else {
    throw new Error('Could not find root element with class "root"');
  }
} catch (error) {
  throw new Error('Could not find root element with class "root"');
}

reportWebVitals();
